.textContainer {
	margin: 0 auto;
	padding: 2rem 1rem;
	text-align: center;
	text-justify: inter-word;
}

.innerContainerSmall {
	max-width: 40rem !important;
	margin-top: 5rem;
	margin-bottom: 5rem;
}
.pricing {
	display: flex;
	/* flex-wrap: wrap; */
	justify-content: center;
	margin-top: 4rem;
}
.headingOne {
	font-size: 2rem;
	font-weight: 700;
	line-height: 3rem;
}
.blackHeading {
	font-size: 2rem;
	font-weight: 700;
	line-height: 3rem;
}
.fontGreen {
	color: #87bc00;
}
.pricing .plan {
	background-color: #fff;
	padding: 2rem 1rem;
	margin: 12px;
	border-radius: 12px;
	text-align: center;
	transition: 0.3s;
	margin-bottom: 40px;
}
.pricing .plan h2 {
	font-size: 22px;
	margin-bottom: 12px;
	font-weight: 600;
}
.pricing .plan .price {
	margin-bottom: 1rem;
	font-size: 30px;
	font-weight: 700;
}
.pricing .plan ul.features {
	list-style-type: none;
	text-align: left;
}
.pricing .plan ul.features li {
	margin: 8px;
	font-size: 1rem;
}
.pricing .plan ul.features li .fas {
	margin-right: 4px;
}
.pricing .plan ul.features li .fa-check-circle {
	color: #8cb918;
}
.pricing .plan ul.features li .fa-times-circle {
	color: #eb4d4b;
}
.pricing .plan button {
	border: none;
	width: 100%;
	padding: 12px 35px;
	margin-top: 1rem;
	background-color: #8cb918;
	color: #fff;
	font-weight: 600;
	border-radius: 50px;
	cursor: pointer;
	font-size: 16px;
}
.pricing .plan.popular {
	border: 2px solid #8cb918;
	position: relative;
	transform: scale(1.08);
}
.pricing .plan.popular span {
	position: absolute;
	top: -20px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #8cb918;
	color: #fff;
	padding: 4px 20px;
	font-size: 18px;
	border-radius: 12px;
}
.pricing .plan:hover {
	box-shadow: 5px 7px 67px -28px rgba(0, 0, 0, 0.37);
}

.toggleContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px; /* spacing between elements */
}

.toggleSwitch .custom-switch-input:checked ~ .custom-switch-label::before {
	background-color: #8cb918; /* Changing the toggle color to green when checked */
}

.toggleSwitch .custom-switch-label::before {
	background-color: #ddd; /* Changing the default toggle color */
	width: 60px; /* Making the toggle wider */
	height: 30px; /* Making the toggle taller */
	border-radius: 15px; /* Adjusting for new height */
}

.toggleSwitch .custom-switch-label::after {
	width: 26px; /* Adjusting the circle inside the toggle */
	height: 26px; /* Making it slightly smaller than the container for a gap */
	top: 2px; /* Centering it vertically */
}

.toggleLabelActive {
	color: #8cb918;
}

/* You can also add some transition for smoothness */
.toggleSwitch .custom-switch-label::before,
.toggleSwitch .custom-switch-label::after {
	transition: all 0.2s;
}

/* media queries for mobile responsiveness */

@media (max-width: 768px) {
	.pricing {
		flex-direction: column;
	}
	.pricing .plan {
		margin: 12px 0;
	}
	.pricing .plan.popular {
		transform: scale(1);
	}
	.pricing .plan.popular span {
		top: -10px;
	}
}