@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
	margin: 0;
	font-family:
		'Inter',
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #01334b;
	background-color: #fafafa;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

button {
	background-color: #8cb918;
	border: none;
	color: #01334b;
	font-weight: 500;
	padding: 8px 24px;
	border-radius: 8px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
}

.btn-primary {
	color: #01334b;
	background-color: #8cb918;
	border-color: #8cb918;
	border-radius: 50px;
	font-weight: 500;
}

.btn-primary:hover {
	color: #fff;
	background-color: #699200;
	border-color: #699200;
	border-radius: 50px;
	font-weight: 500;
}

.btn-primary:disabled {
	color: #699200;
	background-color: #f4ffd9;
	border-color: #f4ffd9;
	border-radius: 50px;
	font-weight: 500;
}

.btn-link {
	color: #8cb918;
	background-color: transparent;
	border-color: transparent;
	border-radius: 50px;
	font-weight: 500;
}

.btn-link:hover {
	color: #699200;
	background-color: transparent;
	border-color: transparent;
	border-radius: 50px;
	font-weight: 500;
}

.btn-link:disabled {
	color: #699200;
	background-color: transparent;
	border-color: transparent;
	border-radius: 50px;
	font-weight: 500;
}

.btn-danger {
	color: #ffffff;
	background-color: #d0021b;
	border-color: #d0021b;
	border-radius: 50px;
	font-weight: 500;
}

.btn-danger:hover {
	color: #fff;
	background-color: #9f0012;
	border-color: #9f0012;
	border-radius: 50px;
	font-weight: 500;
}

.btn-secondary {
	color: #ffffff;
	background-color: #01334b;
	border-color: #01334b;
	border-radius: 50px;
	font-weight: 500;
}

.btn-secondary:hover {
	color: #fff;
	background-color: #002435;
	border-color: #002435;
	border-radius: 50px;
	font-weight: 500;
}

.btn-secondary:disabled {
	color: #fff;
	background-color: #005983;
	border-color: #005983;
	border-radius: 50px;
	font-weight: 500;
}

.btn-primary-outline {
	color: #01334b;
	background-color: transparent;
	border-color: #01334b;
	border-radius: 8px;
	font-weight: 500;
}

.btn-primary-outline:hover {
	color: #fff;
	background-color: #699200;
	border-color: #699200;
	border-radius: 8px;
	font-weight: 500;
}

.btn-primary-outline:disabled {
	color: #699200;
	background-color: #f4ffd9;
	border-color: #f4ffd9;
	border-radius: 8px;
	font-weight: 500;
}

.btn-danger-outline {
	color: #d0021b;
	background-color: transparent;
	border-color: #d0021b;
	border-radius: 50px;
	font-weight: 500;
}

.btn-danger-outline:hover {
	color: #fff;
	background-color: #9f0012;
	border-color: #9f0012;
	border-radius: 50px;
	font-weight: 500;
}

.modal-content {
	border-radius: 20px;
}

.modal-title {
	font-weight: 600;
	font-size: 16px;
}

.modal-header {
	border-bottom: none;
}

.modal-footer {
	border-top: none;
}

.form-label {
	font-weight: 600;
}

.card {
	border-radius: 16px;
	border: 1px solid #d4e4ec;
}

.card-green-top {
	border-top: 4px solid #8cb918;
}
.card-green-top:hover {
	box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
	transition: all 0.1s ease-in-out;
}

a {
	color: #8cb918;
	text-decoration: none;
}

a:hover {
	color: #699200;
	text-decoration: none;
}

.nav-tabs .nav-link {
	border: 0px solid #8cb918;
	border-radius: 0px;
}

.nav-tabs .nav-link:not(.active) {
	color: #606367;
}

.nav-tabs .nav-link.active {
	border: none;
	border-bottom: 4px solid #8cb918;
	border-radius: 0px;
}

/* primary progress bar 8CB918 */
.progress-bar {
	background-color: #8cb918;
}

/* fully rounded */
.progress {
	border-radius: 20px;
}

@keyframes pulsate {
	0% {
		opacity: 0.5;
	}
	50% {
		opacity: 0.1;
	}
}

/* make bootstrap toggle color different */
.form-check-input:checked {
	background-color: #8cb918 !important;
	border-color: #87bc00 !important;
}
